/* eslint-disable react/no-children-prop */
import { gql } from 'graphql.macro';

//------------------------------------------------------------------------------
// Theme Picker ----------------------------------------------------------------
export const GALLERY_QUERY = gql`
  query Gallery {
    gallery {
      categories {
        id
        name
        order

        images {
          id
          order
          name
          url
          authorName
          authorUrl
        }
      }
    }
  }
`;

export const EVENT_IMAGES_QUERY = gql`
  query EventImages {
    me {
      id
      upcoming {
        id
        title
        themePic
        owner {
          id
        }
      }
      past {
        id
        title
        themePic
        owner {
          id
        }
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Account ---------------------------------------------------------------------
export const ACCOUNT_LOGIN_AS_MUTATION = gql`
  mutation AccountLogin($email: String!) {
    accountLoginAs(email: $email) {
      ok
      apitoken
      errors {
        fieldName
        message
      }
    }
  }
`;

export const ACCOUNT_LOGIN_MUTATION = gql`
  mutation AuthLogin($email: String!, $password: String!) {
    emailAuthLogin(email: $email, password: $password) {
      ok
      apitoken
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const ACCOUNT_SIGNUP_MUTATION = gql`
  mutation AuthSignup($email: String!, $password: String!, $name: String!) {
    emailAuthCreate(email: $email, password: $password, name: $name) {
      ok
      apitoken
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const ACCOUNT_LOGOUT_MUTATION = gql`
  mutation AccountLogout {
    accountLogout {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const GOOGLE_LOGIN_AUTH_MUTATION = gql`
  mutation GoogleAuthLogin($code: String!) {
    googleAuthLogin(code: $code) {
      ok
      apitoken
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const GOOGLE_SIGNUP_AUTH_MUTATION = gql`
  mutation GoogleAuthSignup($code: String!) {
    googleAuthCreate(code: $code) {
      ok
      apitoken
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const GOOGLE_AUTH_AUTHORIZE = gql`
  mutation GoogleAuthAuthorize($idToken: String!) {
    googleAuthAuthorize(idToken: $idToken) {
      ok
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($password: String!) {
    emailAuthUpdate(password: $password) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    emailAuthResetPassword(email: $email) {
      ok
      email
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

export const CHANGE_NAME_MUTATION = gql`
  mutation ChangeName($id: ID!, $name: String!) {
    personUpdate(id: $id, name: $name) {
      ok
      person {
        id
        name
      }
      errors {
        fieldName
        message
      }
    }
  }
`;

export const TOKEN_LOGIN_MUTATION = gql`
  mutation AccountTokenLogin($token: String!) {
    accountTokenLogin(token: $token) {
      ok
      apitoken
      email
      errors {
        fieldName
        message
      }
    }
  }
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmailByToken($token: String!) {
    emailAuthVerifyByToken(token: $token) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const PERSON_SET_FEATURE_MUTATION = gql`
  mutation SetFeatureFlag($id: ID!, $featureName: String!, $value: Boolean!) {
    personSetFeature(id: $id, featureName: $featureName, value: $value) {
      ok
      person {
        id
        features
      }
      errors {
        fieldName
        message
      }
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL_MUTATION = gql`
  mutation ResendEmailConfirmation($email: String!) {
    resendConfirmationEmail(email: $email) {
      ok
      email
      errors {
        fieldName
        message
        code
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Invites----------------------------------------------------------------------
export const INVITE_FRAGMENT = gql`
  fragment InviteFragment on Invite {
    id
    state
    rsvpAnswer
    originalName
    originalEmail
    rsvpName
    rsvpEmail
    rsvpPlusN
    isFollowing
    isHost
    updatedAt
  }
`;

export const EVENT_INVITEES_QUERY = gql`
  query EventInvitees($eventId: ID!) {
    eventInvitees(eventId: $eventId) {
      email
      name
    }
  }
`;

export const DELETE_INVITE_MUTATION = gql`
  mutation DeleteInvite($ids: [ID!]!) {
    inviteDelete(ids: $ids) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const EDIT_INVITE_MUTATION = gql`
  mutation EditInviteRSVP($id: ID!, $state: String!, $originalName: String!, $rsvpPlusN: Int!) {
    inviteEditRsvp(id: $id, state: $state, originalName: $originalName, rsvpPlusN: $rsvpPlusN) {
      ok
      invite {
        id
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Roles------------------------------------------------------------------------
export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    role
    createdAt
    person {
      id
      name
    }
    invite {
      id
    }
  }
`;

export const ROLE_INVITE_FRAGMENT = gql`
  fragment RoleInviteFragment on RoleInvite {
    id
    role
    name
    email
    sentBy {
      id
      name
    }
    createdAt
    updatedAt
  }
`;

export const ROLES_QUERY = gql`
  query RolesQuery($eventId: ID!) {
    roles(eventId: $eventId) {
      ...RoleFragment
    }
  }
  ${ROLE_FRAGMENT}
`;

export const ROLE_INVITES_QUERY = gql`
  query RoleInvitesQuery($eventId: ID!) {
    roleInvites(eventId: $eventId) {
      ...RoleInviteFragment
    }
  }
  ${ROLE_INVITE_FRAGMENT}
`;

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($eventId: ID!, $role: String!, $email: String!, $name: String) {
    createRole(eventId: $eventId, role: $role, email: $email, name: $name) {
      ok
      isInvite
      label
      errors {
        fieldName
        message
      }
    }
  }
`;

export const ACCEPT_ROLE_MUTATION = gql`
  mutation AcceptRole($eventId: ID!, $inviteId: ID!) {
    acceptRole(eventId: $eventId, inviteId: $inviteId) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const RESEND_ROLE_INVITE_MUTATION = gql`
  mutation ResendInvite($inviteId: ID!) {
    resendInvite(inviteId: $inviteId) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const DELETE_ROLE_INVITE_MUTATION = gql`
  mutation DeleteRoleInvite($id: ID!) {
    deleteRoleInvite(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Comments --------------------------------------------------------------------
const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    text
    edited
    createdAt
    updatedAt
    replyTo

    canEdit
    canDelete

    author {
      id
      name
    }
  }
`;

export const THREAD_QUERY = gql`
  query ThreadQuery($id: ID!) {
    thread(id: $id) {
      id
      channel
      comments {
        ...CommentFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($eventId: ID!, $text: String!, $replyTo: ID, $nonce: String) {
    eventCommentCreate(eventId: $eventId, text: $text, replyTo: $replyTo, nonce: $nonce) {
      ok
      errors {
        message
      }

      comment {
        ...CommentFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($commentId: ID!, $text: String!) {
    commentUpdate(commentId: $commentId, text: $text) {
      ok
      errors {
        message
      }

      comment {
        ...CommentFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($commentId: ID!) {
    commentDelete(commentId: $commentId) {
      ok
      errors {
        message
      }
    }
  }
`;

export const MARK_THREAD_READ_MUTATION = gql`
  mutation MarkThreadRead($threadId: ID!) {
    markThreadRead(threadId: $threadId) {
      ok
      errors {
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Runs-----------------------------------------------------------------------
export const RUN_READ_QUERY = gql`
  query RunEventId($id: ID!) {
    run(id: $id) {
      id
      eventId
      startOccurrence
      endOccurrence
      location
      timezone
    }
  }
`;

//------------------------------------------------------------------------------
// Events-----------------------------------------------------------------------
export const INVITE_TOKEN_QUERY = gql`
  query InviteTokenQuery($token: String!) {
    inviteToken(token: $token) {
      token
      didRsvp
      isFollowing
      userEmail
      userName
      userId
      userMethod
      userHasFullAccount
      currentUserInvited
      eventId
      eventPrivacy
    }
  }
`;

const VOTE_FRAGMENT = gql`
  fragment VoteFragment on PollVoteType {
    id
    optionId

    name
    person {
      id
      name
    }
  }
`;

const OPTION_FRAGMENT = gql`
  fragment OptionFragment on PollOptionType {
    id
    text
  }
`;

const TICKET_TYPE_FRAGMENT = gql`
  fragment TicketTypeFragment on TicketTypeType {
    id
    name
    priceType
    description
    suggestedDonationMinCents
    suggestedDonationDefaultCents
    fixedPriceCents
    capacity
    quantityClaimed
    position
  }
`;

export const EVENT_SCHEDULED_REMINDER_FRAGMENT = gql`
  fragment EventScheduledReminderFragment on EventScheduledReminder {
    id
    createdAt
    message
    disabled
    eventId
    sent
    scheduledFor
    interval {
      unit
      quantity
    }
  }
`;

export const DISCOUNT_CODE_FRAGMENT = gql`
  fragment DiscountCodeFragment on DiscountCodeType {
    id
    type
    code
    amount
  }
`;

export const POLL_FRAGMENT = gql`
  fragment PollFragment on PollType {
    id
    type

    options {
      ...OptionFragment
    }

    votes {
      ...VoteFragment
    }
  }
  ${OPTION_FRAGMENT}
  ${VOTE_FRAGMENT}
`;

const PERMISSIONS_FRAGMENT = gql`
  fragment PermissionsFragment on EventPersonPermissions {
    canInvite
    canMessageGuests
    canUpdate
    canViewGuestList
    canScheduleInvites
  }
`;

const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    id
    title
    description
    location
    startTime
    timezone
    endTime
    showGuestList
    guestsCanInvite
    privacy
    currency
    themePic
    maxCapacity
    maxPlusN
    confirmedGuests
    invitedGuests
    password
  }
`;

const EVENT_FOR_CALENDAR_FRAGMENT = gql`
  fragment EventForCalendarFragment on ForCalendar {
    description
    end
    location
    start
    timezone
    title
    eventUrl
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent(
    $captureGuestPhone: Boolean!
    $confirmedGuestMessage: String
    $description: String
    $locationReleaseTimeDelta: Float
    $disableComments: Boolean!
    $discountCodes: [DiscountCodeInput!]
    $endTime: String
    $facebookPixelId: String
    $guestsCanInvite: Boolean!
    $hostedByOverride: String
    $location: String
    $maxCapacity: Int
    $maxPlusN: Int
    $password: String
    $poll: [String!]
    $privacy: String
    $currency: String!
    $showGuestList: Boolean!
    $startTime: String
    $themePic: String
    $ticketTypes: [TicketTypeInput!]
    $timezone: String!
    $title: String!
    $registrationEndTime: String
    $shouldSendConfirmation: Boolean
  ) {
    eventCreate(
      captureGuestPhone: $captureGuestPhone
      confirmedGuestMessage: $confirmedGuestMessage
      description: $description
      disableComments: $disableComments
      discountCodes: $discountCodes
      endTime: $endTime
      facebookPixelId: $facebookPixelId
      guestsCanInvite: $guestsCanInvite
      hostedByOverride: $hostedByOverride
      location: $location
      locationReleaseTimeDelta: $locationReleaseTimeDelta
      maxCapacity: $maxCapacity
      maxPlusN: $maxPlusN
      password: $password
      poll: $poll
      privacy: $privacy
      currency: $currency
      registrationEndTime: $registrationEndTime
      showGuestList: $showGuestList
      startTime: $startTime
      themePic: $themePic
      ticketTypes: $ticketTypes
      timezone: $timezone
      title: $title
      shouldSendConfirmation: $shouldSendConfirmation
    ) {
      ok
      errors {
        fieldName
        message
      }
      event {
        id
        title
      }
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent(
    $captureGuestPhone: Boolean!
    $confirmedGuestMessage: String
    $description: String
    $locationReleaseTimeDelta: Float
    $disableComments: Boolean!
    $discountCodes: [DiscountCodeInput!]
    $endTime: String
    $facebookPixelId: String
    $guestsCanInvite: Boolean!
    $hostedByOverride: String
    $id: ID!
    $location: String
    $maxCapacity: Int
    $maxPlusN: Int
    $password: String
    $poll: [String!]
    $privacy: String!
    $currency: String!
    $showGuestList: Boolean!
    $startTime: String
    $themePic: String
    $ticketTypes: [TicketTypeInput!]
    $timezone: String!
    $title: String!
    $registrationEndTime: String
    $shouldSendConfirmation: Boolean
  ) {
    eventUpdate(
      captureGuestPhone: $captureGuestPhone
      confirmedGuestMessage: $confirmedGuestMessage
      description: $description
      disableComments: $disableComments
      discountCodes: $discountCodes
      endTime: $endTime
      facebookPixelId: $facebookPixelId
      guestsCanInvite: $guestsCanInvite
      hostedByOverride: $hostedByOverride
      id: $id
      location: $location
      locationReleaseTimeDelta: $locationReleaseTimeDelta
      maxCapacity: $maxCapacity
      maxPlusN: $maxPlusN
      password: $password
      poll: $poll
      privacy: $privacy
      currency: $currency
      registrationEndTime: $registrationEndTime
      showGuestList: $showGuestList
      startTime: $startTime
      themePic: $themePic
      ticketTypes: $ticketTypes
      timezone: $timezone
      title: $title
      shouldSendConfirmation: $shouldSendConfirmation
    ) {
      ok
      errors {
        fieldName
        message
      }
      event {
        ...EventFragment
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($id: ID!) {
    eventDelete(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const EVENT_WITH_PASSWORD_QUERY = gql`
  query EventWithPasswordQuery($id: ID!, $password: String) {
    eventWithPassword(id: $id, password: $password) {
      ok
      id
      title
      themePic
      hostedByText
      bgNoBackdrop
      bgColor
      hasPassword
      isAuthorized
      errors {
        fieldName
        message
      }
    }
  }
`;

export const VERIFY_EVENT_WITH_PASSWORD_MUTATION = gql`
  mutation VerifyEventWithPasswordMutation($id: ID!, $password: String) {
    verifyEventWithPassword(id: $id, password: $password) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

// TODO: Fix for anon user
// password will only be served to hosts
export const EVENT_WITH_EXIST_QUERY = gql`
  query EventWithExistQuery($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      id
      title
      description
      location
      startTime
      timezone
      registrationEndTime
      endTime
      guestsCanInvite
      showGuestList
      privacy
      currency
      themePic
      maxCapacity
      confirmedGuests
      invitedGuests
      maxPlusN
      password
      updatedAt
      createdAt
      checkoutButtonLabel
      numTickets
      captureGuestPhone
      confirmedGuestMessage
      hostedByOverride
      disableComments
      facebookPixelId
      showDiscountCodeInput
      bgColor
      bgNoBackdrop
      owner {
        id
        name
      }
      hostedByText
      myInvite {
        ...InviteFragment
      }
      myPermissions {
        ...PermissionsFragment
      }
      guestPermissions {
        ...PermissionsFragment
      }
      myTickets {
        id
        order {
          id
        }
      }
      thread {
        id
      }
      poll {
        ...PollFragment
      }
      ticketTypes {
        ...TicketTypeFragment
      }
      eventsFromRun {
        id
        startTime
        endTime
        description
        isPast
        partOfRunId
        ticketTypes {
          ...TicketTypeFragment
        }
      }
      discountCodes {
        ...DiscountCodeFragment
        isActive
        quantityClaimed
      }
      isPast
      partOfRunId
      isRunTemplate
      appendAccessTokenToUrl
      proxyVirtualLocation
      locationReleaseTimeDelta
      useMixilyVirtualVenue
      virtualVenueContent
      forCalendar {
        ...EventForCalendarFragment
      }
    }
    eventExists(id: $id)
  }
  ${INVITE_FRAGMENT}
  ${PERMISSIONS_FRAGMENT}
  ${POLL_FRAGMENT}
  ${TICKET_TYPE_FRAGMENT}
  ${DISCOUNT_CODE_FRAGMENT}
  ${EVENT_FOR_CALENDAR_FRAGMENT}
`;

export const EVENT_FOR_VENUE_FRAGMENT = gql`
  fragment EventForVenueFragment on Event {
    id
    title
    description
    location
    startTime
    timezone
    endTime
    themePic
    updatedAt
    createdAt
    hostedByOverride
    bgColor
    bgNoBackdrop
    hostedByText
    isPast
    appendAccessTokenToUrl
    proxyVirtualLocation
    locationReleaseTimeDelta
    useMixilyVirtualVenue
    virtualVenueContent
    currency
  }
`;

export const EVENT_FOR_VENUE_QUERY = gql`
  query EventForVenueQuery($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      ...EventForVenueFragment
    }
    ${EVENT_FOR_VENUE_FRAGMENT}
  }
`;

export const EVENT_TICKET_LIST_QUERY = gql`
  query EventTicketList($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      id
      ticketSet {
        id
        cost
        ticketType {
          id
          fixedPriceCents
        }
        order {
          id
          guestListName
          email
          guestListPhone
          discounts {
            id
            discountCode {
              ...DiscountCodeFragment
            }
          }
        }
      }
    }
  }
  ${DISCOUNT_CODE_FRAGMENT}
`;

export const EVENT_GUEST_LIST_QUERY = gql`
  query EventGuestList($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      id
      inviteSet {
        ...InviteFragment
        affiliation
        sentBy {
          id
          name
        }
        person {
          id
          name
        }
      }
    }
  }
  ${INVITE_FRAGMENT}
`;

export const EVENT_ORDERS_QUERY = gql`
  query EventOrders($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      id
      orderSet {
        id
        safeGuestListName
        guestListPhone
        safeEmail
        subtotal
        status
        total
        discountSum
        appFee
        stripeFee
        createdAt
        tickets {
          id
          cost
          ticketType {
            ...TicketTypeFragment
          }
        }
        discounts {
          id
          amount
          discountCode {
            ...DiscountCodeFragment
          }
        }
      }
    }
  }
  ${TICKET_TYPE_FRAGMENT}
  ${DISCOUNT_CODE_FRAGMENT}
`;

export const EVENT_INVITES_QUERY = gql`
  query EventInvites($id: ID!, $password: String) {
    event(id: $id, password: $password) {
      id
      inviteSetWithoutOwner {
        ...InviteFragment
        person {
          id
          name
        }
      }
    }
  }
  ${INVITE_FRAGMENT}
`;

export const MESSAGE_GUEST_MUTATION = gql`
  mutation MessageGuest($id: ID!, $filter: String!, $message: String!, $scheduledFor: String) {
    eventMessageGuests(id: $id, filter: $filter, message: $message, scheduledFor: $scheduledFor) {
      ok
      isScheduled
      errors {
        fieldName
        message
      }
    }
  }
`;

export const EVENT_RSVP_BY_HOST_MUTATION = gql`
  mutation EventManualRsvpByHost($eventId: ID!, $name: String!, $email: String!) {
    eventManualRsvpByHost(eventId: $eventId, name: $name, email: $email) {
      ok
      errors {
        fieldName
        message
        code
      }
      invite {
        ...InviteFragment
      }
    }
  }
  ${INVITE_FRAGMENT}
`;

export const EVENT_RSVP_CREATE_MUTATION = gql`
  mutation CreateEventRSVP(
    $eventId: ID!
    $email: String!
    $name: String!
    $inviteState: String!
    $rsvpAnswer: String!
    $rsvpPlusN: Int
    $isFollowing: Boolean
  ) {
    eventRsvpCreate(
      eventId: $eventId
      email: $email
      name: $name
      inviteState: $inviteState
      rsvpAnswer: $rsvpAnswer
      rsvpPlusN: $rsvpPlusN
      isFollowing: $isFollowing
    ) {
      ok
      errors {
        message
        fieldName
      }
      signedCookie {
        name
        value
      }
      invite {
        ...InviteFragment
      }
    }
  }
  ${INVITE_FRAGMENT}
`;

export const EVENT_RSVP_UPDATE_MUTATION = gql`
  mutation UpdateEventRSVP(
    $eventId: ID!
    $inviteState: String!
    $rsvpAnswer: String!
    $rsvpName: String
    $rsvpPlusN: Int
    $isFollowing: Boolean
  ) {
    eventRsvpUpdate(
      eventId: $eventId
      inviteState: $inviteState
      rsvpAnswer: $rsvpAnswer
      rsvpName: $rsvpName
      rsvpPlusN: $rsvpPlusN
      isFollowing: $isFollowing
    ) {
      invite {
        ...InviteFragment
      }
      ok
    }
  }
  ${INVITE_FRAGMENT}
`;

const EVENT_FOR_PERSON_FRAGMENT = gql`
  fragment EventPersonFragment on Event {
    id
    title
    description
    startTime
    endTime
    timezone
    location
    hostedByText
    themePic
    numInvites
    isTicketed
    isPast
    appendAccessTokenToUrl
    proxyVirtualLocation
    currency
    run {
      id
    }
    owner {
      id
    }
    forCalendar {
      ...EventForCalendarFragment
    }
  }
  ${EVENT_FOR_CALENDAR_FRAGMENT}
`;

export const EVENTS_FOR_PERSON_QUERY = gql`
  query EventsForPerson {
    me {
      id
      upcoming {
        ...EventPersonFragment
      }
      past {
        ...EventPersonFragment
      }
    }
  }
  ${EVENT_FOR_PERSON_FRAGMENT}
`;

export const EVENT_SUMMARIES_FOR_PERSON_QUERY = gql`
  query EventSummariesForPerson {
    me {
      id
      eventSummaries {
        id
        title
        themePic
        startTime
        attendees
        tickets
        grossSales
        currency
        netSales
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Listings --------------------------------------------------------------------

export const LISTING_FRAGMENT = gql`
  fragment ListingFragment on Listing {
    id
    personId
    title
    description
    themePic
    bgColor
    bgNoBackdrop
    listingEvents {
      id
      position
      eventId
      event {
        ...EventPersonFragment
      }
    }
  }
  ${EVENT_FOR_PERSON_FRAGMENT}
`;

export const LISTING_QUERY = gql`
  query Listing($id: ID!) {
    listing(id: $id) {
      ...ListingFragment
    }
  }
  ${LISTING_FRAGMENT}
`;

export const CREATE_LISTING_MUTATION = gql`
  mutation CreateListing($title: String!, $description: String, $themePic: String) {
    createListing(title: $title, description: $description, themePic: $themePic) {
      ok
      listing {
        ...ListingFragment
      }
      errors {
        fieldName
        message
      }
    }
  }
  ${LISTING_FRAGMENT}
`;

export const UPDATE_LISTING_MUTATION = gql`
  mutation UpdateListing(
    $id: ID!
    $title: String!
    $listingEvents: [ListingEventInput!]!
    $description: String
    $themePic: String
  ) {
    updateListing(
      id: $id
      title: $title
      listingEvents: $listingEvents
      description: $description
      themePic: $themePic
    ) {
      ok
      listing {
        ...ListingFragment
      }
      errors {
        fieldName
        message
      }
    }
  }
  ${LISTING_FRAGMENT}
`;

export const DELETE_LISTING_MUTATION = gql`
  mutation DeleteListing($id: ID!) {
    deleteListing(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Email------------------------------------------------------------------------

// TODO: have the consumer of EmailPreviewModal specify the query to use.
// If you're adding a third Query to this modal consider refactoring instead
export const INVITE_EMAIL_PREVIEW_QUERY = gql`
  query InviteEmailPreviewQuery($eventId: ID!, $message: String) {
    inviteEmailPreview(eventId: $eventId, message: $message) {
      subject
      bodyHtml
    }
  }
`;

export const REMINDER_EMAIL_PREVIEW_QUERY = gql`
  query ReminderEmailPreviewQuery($eventId: ID!, $message: String) {
    reminderEmailPreview(eventId: $eventId, message: $message) {
      subject
      bodyHtml
    }
  }
`;

export const MESSAGE_EMAIL_PREVIEW_QUERY = gql`
  query MessageEmailPreviewQuery($eventId: ID!, $message: String!) {
    messageEmailPreview(eventId: $eventId, message: $message) {
      subject
      bodyHtml
    }
  }
`;

export const OUTGOING_MESSAGES_QUERY = gql`
  query OutgoingMessages($eventId: ID) {
    scheduledMessages(eventId: $eventId) {
      id
      createdAt
      message
      sent
      scheduledFor
      eventId
      guestFilter
    }
  }
`;

export const DELETE_OUTGOING_MESSAGES_MUTATION = gql`
  mutation DeleteOutgoingMessages($ids: [ID!]!) {
    scheduledMessagesDelete(messageIds: $ids) {
      ok
      deletedMessages
      errors {
        fieldName
        message
      }
    }
  }
`;

export const EVENT_SCHEDULED_REMINDER_QUERY = gql`
  query EventScheduledReminders($eventId: ID!) {
    eventScheduledReminders(eventId: $eventId) {
      ...EventScheduledReminderFragment
    }
  }
  ${EVENT_SCHEDULED_REMINDER_FRAGMENT}
`;

export const UPDATE_EVENT_SCHEDULED_REMINDER_MUTATION = gql`
  mutation UpdateEventScheduledReminder(
    $id: ID!
    $disabled: Boolean
    $message: String
    $interval: SimpleDurationInput
  ) {
    updateEventScheduledReminder(id: $id, disabled: $disabled, message: $message, interval: $interval) {
      ok
      eventScheduledReminder {
        ...EventScheduledReminderFragment
      }
      errors {
        fieldName
        message
      }
    }
  }
  ${EVENT_SCHEDULED_REMINDER_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Event RSVPs -----------------------------------------------------------------

export const CREATE_INVITE_MUTATION = gql`
  mutation CreateInvite($eventId: ID!, $invitees: [InviteeInput!]!, $scheduledFor: String) {
    inviteCreate(eventId: $eventId, invitees: $invitees, scheduledFor: $scheduledFor) {
      ok
      isScheduled
      errors {
        fieldName
        message
      }
      inviteResults {
        result
        invite {
          invitedByEmail
        }
      }
    }
  }
`;

export const OUTGOING_INVITATIONS_QUERY = gql`
  query OutgoingInvitations($eventId: ID, $includeSent: Boolean, $sentById: ID) {
    invitations(eventId: $eventId, includeSent: $includeSent, sentById: $sentById) {
      id
      originalEmail
      originalName
      rsvpName
      rsvpEmail
      eventId
      state
      scheduledFor
    }
  }
`;

export const DELETE_OUTGOING_INVITATIONS_MUTATION = gql`
  mutation DeleteOutgoingInvitations($ids: [ID!]!) {
    inviteDelete(ids: $ids) {
      ok
      deletedInvites
      errors {
        fieldName
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Polls -----------------------------------------------------------------------

export const POLL_QUERY = gql`
  query pollQuery($id: ID!) {
    poll(id: $id) {
      ...PollFragment
    }
  }
  ${POLL_FRAGMENT}
`;

export const POLL_VOTE_MUTATION = gql`
  mutation pollVote($pollId: ID!, $optionIds: [ID!]!, $name: String) {
    pollVoteBulkUpdate(pollId: $pollId, optionIds: $optionIds, name: $name) {
      ok
      errors {
        message
      }

      votes {
        ...VoteFragment
      }

      deletedVoteIds
    }
  }
  ${VOTE_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Invite Magic Link -----------------------------------------------------------
export const INVITE_SEND_MAGIC_LINK_MUTATION = gql`
  mutation SendMagicLink($eventId: ID!, $email: String!) {
    inviteSendMagicLink(eventId: $eventId, email: $email) {
      ok
      errors {
        message
      }
    }
  }
`;

export const INVITE_USE_MAGIC_LINK_MUTATION = gql`
  mutation InviteUseMagicLink($token: String!) {
    inviteUseMagicLink(token: $token) {
      ok
      errors {
        message
      }

      expired
      event {
        id
      }

      apitoken
      signedCookie {
        name
        value
      }
    }
  }
`;
//------------------------------------------------------------------------------
// Contacts --------------------------------------------------------------------
export const CONTACT_SEARCH_QUERY = gql`
  query ContactSearch($query: String!) {
    contactSearch(searchQuery: $query) {
      id
      name
      email
      score
    }
  }
`;

export const CONTACT_SUMMARIES_FOR_PERSON_QUERY = gql`
  query ContactSummariesForPerson {
    me {
      id
      contactSummaries {
        name
        email
        numEvents
        firstSeen {
          id
          title
        }
        lastSeen {
          id
          title
        }
        totalRevenue
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Contact Lists ---------------------------------------------------------------
export const CONTACTS_AND_LIST_QUERY = gql`
  query ContactsAndListsQuery($searchQuery: String!, $eventId: String!) {
    contactsAndListsSearch(searchQuery: $searchQuery, eventId: $eventId) {
      contacts {
        name
        email
        score
      }
      contactLists {
        name
        totalContacts
        contacts {
          contact {
            name
            email
          }
        }
      }
    }
  }
`;

export const MY_CONTACT_LISTS_QUERY = gql`
  query MyContactLists {
    me {
      id
      contactLists {
        id
        name
        totalContacts
      }
    }
  }
`;

export const CONTACT_LIST_QUERY = gql`
  query ContactList($id: ID!) {
    contactList(id: $id) {
      id
      name
      contacts {
        id
        contact {
          id
          name
          email
        }
      }
    }
  }
`;

export const CREATE_CONTACT_LIST_MUTATION = gql`
  mutation CreateContactList($name: String!) {
    contactListCreate(name: $name) {
      ok
      contactList {
        id
        name
      }
    }
  }
`;

export const ADD_CONTACT_TO_LIST_MUTATION = gql`
  mutation AddContactToList($contactListId: ID!, $contactId: ID, $contactInput: ContactInput) {
    contactListContactAdd(contactListId: $contactListId, contactId: $contactId, contactInput: $contactInput) {
      ok
      errors {
        message
      }

      contactListContact {
        id
        contact {
          id
          name
          email
        }
      }
    }
  }
`;

export const REMOVE_CONTACT_FROM_LIST_MUTATION = gql`
  mutation RemoveContactFromList($contactListContactId: ID!) {
    contactListContactRemove(contactListContactId: $contactListContactId) {
      ok
    }
  }
`;

export const CONTACT_LIST_UPDATE_MUTATION = gql`
  mutation UpdateContactList($id: ID!, $name: String!) {
    contactListUpdate(id: $id, name: $name) {
      ok
      contactList {
        id
      }
    }
  }
`;

export const CONTACT_LIST_DELETE_MUTATION = gql`
  mutation RemoveContactList($id: ID!) {
    contactListDelete(id: $id) {
      ok
    }
  }
`;

export const CREATE_IMPORTED_GOOGLE_CONTACT_MUTATION = gql`
  mutation CreateImportedGoogleContact($auth_token: String!) {
    importedContactCreate(authToken: $auth_token) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// User & Authentication -------------------------------------------------------
export const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      name
      enableProEmails
      stripeAccountId
      stripeChargesEnabled
      stripeCustomerId
      zoomUserId
      listingId
      auths {
        email {
          email
        }
        google {
          email
          isCalendarAuthorized
        }
        isVerified
      }
      mySubscription {
        type
      }
      features
      contactLists {
        id
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Tickets ---------------------------------------------------------------------

export const ORDER_READ_QUERY = gql`
  query ReadOrder($id: ID!) {
    order(id: $id) {
      id
      createdAt
      updatedAt
      total
      status
      email
      locationId
      lineItems {
        name
        description
        images
        amount
        quantity
        currency
      }
      tickets {
        id
        cost
        ticketType {
          id
          event {
            id
            title
            startTime
            endTime
            timezone
            location
            title
            description
            confirmedGuestMessage
            facebookPixelId
            partOfRunId
            appendAccessTokenToUrl
            proxyVirtualLocation
            currency
            forCalendar {
              ...EventForCalendarFragment
            }
          }
        }
      }
      discounts {
        id
        amount
        discountCode {
          ...DiscountCodeFragment
        }
      }
    }
  }
  ${DISCOUNT_CODE_FRAGMENT}
  ${EVENT_FOR_CALENDAR_FRAGMENT}
`;

export const ORDER_WITH_EVENT_BY_LOCATION_ID_QUERY = gql`
  query OrderWithEventByLocationId($locationId: ID!) {
    orderByLocationId(locationId: $locationId) {
      id
      locationId
      eventAccessCount
      tickets {
        ticketType {
          name
          event {
            id
            title
            startTime
            endTime
            timezone
            location
            title
            themePic
            description
            hostedByText
            bgColor
            bgNoBackdrop
            facebookPixelId
            partOfRunId
            appendAccessTokenToUrl
            proxyVirtualLocation
            currency
            useMixilyVirtualVenue
            virtualVenueContent
            locationReleaseTimeDelta
          }
        }
      }
    }
  }
`;

export const ORDER_REFRESH_MUTATION = gql`
  mutation RefreshOrder($id: ID!) {
    orderRefresh(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder(
    $tickets: [TicketOrderInput!]!
    $discounts: [DiscountOrderInput!]
    $phone: String
    $email: String
    $name: String
  ) {
    orderCreate(tickets: $tickets, discounts: $discounts, phone: $phone, email: $email, name: $name) {
      ok
      errors {
        fieldName
        message
      }
      mode
      stripeCheckoutSessionId
      orderId
    }
  }
`;

export const DISCOUNT_CODE_QUERY = gql`
  query DiscountCodeQuery($eventId: ID!, $code: String!) {
    discountCode(eventId: $eventId, code: $code) {
      ...DiscountCodeFragment
    }
  }
  ${DISCOUNT_CODE_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Subscriptions ---------------------------------------------------------------

export const SUBSCRIPTION_ORDER_QUERY = gql`
  query SubscriptionOrder($id: ID!) {
    subscriptionOrder(id: $id) {
      id
      subscriptionId
      amount
      status
    }
  }
`;

export const CREATE_SUBSCRIPTION_ORDER_MUTATION = gql`
  mutation CreateSubscriptionOrder($priceId: String!, $successUrlRoute: String!) {
    createSubscriptionOrder(priceId: $priceId, successUrlRoute: $successUrlRoute) {
      ok
      stripeCheckoutSessionId
      orderId
      errors {
        fieldName
        message
      }
    }
  }
`;

export const REFRESH_SUBSCRIPTION_ORDER_MUTATION = gql`
  mutation RefreshSubscriptionOrder($id: ID!) {
    refreshSubscriptionOrder(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

//------------------------------------------------------------------------------
// Virtual Venue Theme ---------------------------------------------------------------

export const VIRTUAL_VENUE_THEME_FRAGMENT = gql`
  fragment VirtualVenueThemeFragment on VirtualVenueTheme {
    id
    bgColor
    chatBgColor
    chatTextColor
    gridLineColor
    iconColor
    scrollbarColor
    titleTextColor
    logo
    logoFilter
    owner {
      id
    }
  }
`;

export const VIRTUAL_VENUE_THEME_UPDATE_MUTATION = gql`
  mutation VirtualVenueThemeUpdate(
    $id: ID!
    $logo: String
    $bgColor: String
    $titleTextColor: String
    $iconColor: String
    $gridLineColor: String
    $scrollbarColor: String
    $chatTextColor: String
    $chatBgColor: String
  ) {
    virtualVenueThemeUpdate(
      id: $id
      logo: $logo
      bgColor: $bgColor
      titleTextColor: $titleTextColor
      iconColor: $iconColor
      gridLineColor: $gridLineColor
      scrollbarColor: $scrollbarColor
      chatTextColor: $chatTextColor
      chatBgColor: $chatBgColor
    ) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenueTheme {
        ...VirtualVenueThemeFragment
      }
    }
  }
  ${VIRTUAL_VENUE_THEME_FRAGMENT}
`;

export const VIRTUAL_VENUE_THEME_CREATE_MUTATION = gql`
  mutation VirtualVenueThemeCreate(
    $logo: String
    $bgColor: String
    $titleTextColor: String
    $iconColor: String
    $gridLineColor: String
    $scrollbarColor: String
    $chatTextColor: String
    $chatBgColor: String
  ) {
    virtualVenueThemeCreate(
      logo: $logo
      bgColor: $bgColor
      titleTextColor: $titleTextColor
      iconColor: $iconColor
      gridLineColor: $gridLineColor
      scrollbarColor: $scrollbarColor
      chatTextColor: $chatTextColor
      chatBgColor: $chatBgColor
    ) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenueTheme {
        ...VirtualVenueThemeFragment
      }
    }
  }
  ${VIRTUAL_VENUE_THEME_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Virtual Venue ---------------------------------------------------------------

export const VIRTUAL_VENUE_BASE_FRAGMENT = gql`
  fragment VirtualVenueBaseFragment on VirtualVenue {
    id
    ownerId
    slug
    title
    dailyCallUrl
    broadcastUrl
    requireName
    isClosed
    awayMessage
    subscription {
      type
    }
    theme {
      ...VirtualVenueThemeFragment
    }
  }
  ${VIRTUAL_VENUE_THEME_FRAGMENT}
`;

export const VIRTUAL_VENUE_ACCESS_FRAGMENT = gql`
  fragment VirtualVenueAccessFragment on VirtualVenue {
    myAccess(userId: $userId) {
      role
      dailyToken
      ableToRecord
    }
  }
`;

export const VIRTUAL_VENUE_FRAGMENT = gql`
  fragment VirtualVenueFragment on VirtualVenue {
    ...VirtualVenueBaseFragment
    ...VirtualVenueAccessFragment
  }
  ${VIRTUAL_VENUE_BASE_FRAGMENT}
  ${VIRTUAL_VENUE_ACCESS_FRAGMENT}
`;

export const VIRTUAL_VENUE_QUERY = gql`
  query ReadVirtualVenue($id: ID!, $userId: ID) {
    virtualVenue(id: $id) {
      ...VirtualVenueFragment
      events {
        ...EventForVenueFragment
      }
    }
  }
  ${VIRTUAL_VENUE_FRAGMENT}
  ${EVENT_FOR_VENUE_FRAGMENT}
`;

export const VIRTUAL_VENUE_BY_SLUG_QUERY = gql`
  query ReadVirtualVenueBySlug($slug: String!, $userId: ID) {
    virtualVenueBySlug(slug: $slug) {
      ...VirtualVenueFragment
      events {
        ...EventForVenueFragment
      }
    }
  }
  ${VIRTUAL_VENUE_FRAGMENT}
  ${EVENT_FOR_VENUE_FRAGMENT}
`;

export const VIRTUAL_VENUE_SET_TITLE_MUTATION = gql`
  mutation VirtualVenueSetTitle($id: ID!, $title: String!) {
    virtualVenueUpdate(id: $id, title: $title) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenue {
        id
        title
      }
    }
  }
`;

export const VIRTUAL_VENUE_SET_CLOSED_MUTATION = gql`
  mutation VirtualVenueSetClosed($id: ID!, $isClosed: Boolean!, $awayMessage: String!) {
    virtualVenueUpdate(id: $id, isClosed: $isClosed, awayMessage: $awayMessage) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenue {
        id
        isClosed
        awayMessage
      }
    }
  }
`;

export const VIRTUAL_VENUE_SET_REQUIRE_NAME_MUTATION = gql`
  mutation VirtualVenueSetRequireName($id: ID!, $requireName: Boolean!) {
    virtualVenueUpdate(id: $id, requireName: $requireName) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenue {
        id
        requireName
      }
    }
  }
`;

export const VIRTUAL_VENUE_CREATE_MUTATION = gql`
  mutation VirtualVenueCreate($title: String!, $theme: VirtualVenueThemeInput) {
    virtualVenueCreate(title: $title, theme: $theme) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenue {
        id
        title
        theme {
          ...VirtualVenueThemeFragment
        }
      }
    }
  }
  ${VIRTUAL_VENUE_THEME_FRAGMENT}
`;

export const VIRTUAL_VENUE_UPDATE_MUTATION = gql`
  mutation VirtualVenueUpdate(
    $id: ID!
    $title: String
    $theme: VirtualVenueThemeInput
    $requireName: Boolean
    $isClosed: Boolean
    $awayMessage: String
  ) {
    virtualVenueUpdate(
      id: $id
      title: $title
      theme: $theme
      requireName: $requireName
      isClosed: $isClosed
      awayMessage: $awayMessage
    ) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenue {
        id
        title
        requireName
        isClosed
        awayMessage
        theme {
          ...VirtualVenueThemeFragment
        }
      }
    }
  }
  ${VIRTUAL_VENUE_THEME_FRAGMENT}
`;

export const DELETE_VIRTUAL_VENUE_MUTATION = gql`
  mutation DeleteVirtualVenue($id: ID!) {
    virtualVenueDelete(id: $id) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const VIRTUAL_VENUES_BY_OWNER_QUERY = gql`
  query VirtualVenuesByOwner($ownerId: ID!) {
    virtualVenuesByOwner(ownerId: $ownerId) {
      ...VirtualVenueBaseFragment
      myAccess(userId: $ownerId) {
        ableToRecord
      }
    }
  }
  ${VIRTUAL_VENUE_BASE_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Virtual Venue Recordings ------------------------------------------------------

export const VIRTUAL_VENUE_RECORDING_FRAGMENT = gql`
  fragment VirtualVenueRecordingFragment on VirtualVenueRecording {
    id
    createdAt
    duration
    maxParticipants
    recordingStatus
    compositingStatus
    extRecordingId
    extCompositingId
  }
`;

export const VIRTUAL_VENUE_RECORDING_CREATE_MUTATION = gql`
  mutation VirtualVenueRecordingCreate($venueId: ID!, $extRoomId: String!, $extRecordingId: String!) {
    virtualVenueRecordingCreate(virtualVenueId: $venueId, extRoomId: $extRoomId, extRecordingId: $extRecordingId) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenueRecording {
        ...VirtualVenueRecordingFragment
      }
    }
  }
  ${VIRTUAL_VENUE_RECORDING_FRAGMENT}
`;

export const VIRTUAL_VENUE_RECORDING_DELETE_MUTATION = gql`
  mutation VirtualVenueRecordingDelete($recordingId: ID!) {
    virtualVenueRecordingDelete(id: $recordingId) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;

export const VIRTUAL_VENUE_RECORDING_UPDATE_BY_EXT_RECORDING_ID_MUTATION = gql`
  mutation VirtualVenueRecordingUpdateByExtRecordingId(
    $extRecordingId: String!
    $recordingStatus: ProcessingStates
    $compositingStatus: ProcessingStates
  ) {
    virtualVenueRecordingUpdateByExtRecordingId(
      extRecordingId: $extRecordingId
      recordingStatus: $recordingStatus
      compositingStatus: $compositingStatus
    ) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenueRecording {
        ...VirtualVenueRecordingFragment
      }
    }
  }
  ${VIRTUAL_VENUE_RECORDING_FRAGMENT}
`;

export const VIRTUAL_VENUE_RECORDING_UPDATE_MUTATION = gql`
  mutation VirtualVenueRecordingUpdate(
    $id: ID!
    $recordingStatus: ProcessingStates
    $compositingStatus: ProcessingStates
  ) {
    virtualVenueRecordingUpdate(id: $id, recordingStatus: $recordingStatus, compositingStatus: $compositingStatus) {
      ok
      errors {
        fieldName
        message
      }
      virtualVenueRecording {
        ...VirtualVenueRecordingFragment
      }
    }
  }
  ${VIRTUAL_VENUE_RECORDING_FRAGMENT}
`;

export const VIRTUAL_VENUE_RECORDING_WITH_VENUE_QUERY = gql`
  query VirtualVenueRecordingWithVenue($venueId: ID!, $recordingId: ID!) {
    virtualVenue(id: $venueId) {
      ...VirtualVenueBaseFragment
    }
    virtualVenueRecording(recordingId: $recordingId) {
      ...VirtualVenueRecordingFragment
    }
  }
  ${VIRTUAL_VENUE_BASE_FRAGMENT}
  ${VIRTUAL_VENUE_RECORDING_FRAGMENT}
`;

export const VIRTUAL_VENUE_RECORDINGS_WITH_VENUE_QUERY = gql`
  query VirtualVenueRecordingsWithVenue($venueId: ID!, $userId: ID) {
    virtualVenue(id: $venueId) {
      ...VirtualVenueFragment
    }
    virtualVenueRecordings(venueId: $venueId) {
      ...VirtualVenueRecordingFragment
    }
  }
  ${VIRTUAL_VENUE_FRAGMENT}
  ${VIRTUAL_VENUE_RECORDING_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Virtual Venue Sessions ------------------------------------------------------

export const VIRTUAL_VENUE_SESSION_PARTICIPANT_FRAGMENT = gql`
  fragment VirtualVenueSessionParticipantFragment on VirtualVenueSessionParticipant {
    startTime
    duration
    userName
  }
`;

export const VIRTUAL_VENUE_SESSION_FRAGMENT = gql`
  fragment VirtualVenueSessionFragment on VirtualVenueSession {
    startTime
    duration
    maxParticipants
    totalParticipantSeconds
    participants {
      ...VirtualVenueSessionParticipantFragment
    }
    ${VIRTUAL_VENUE_SESSION_PARTICIPANT_FRAGMENT}
  }
`;

export const VIRTUAL_VENUE_SESSIONS_WITH_VENUE_QUERY = gql`
  query VirtualVenueSessionsWithVenue($venueId: ID!, $userId: ID) {
    virtualVenue(id: $venueId) {
      ...VirtualVenueFragment
    }
    virtualVenueSessions(venueId: $venueId) {
      ...VirtualVenueSessionFragment
    }
  }
  ${VIRTUAL_VENUE_FRAGMENT}
  ${VIRTUAL_VENUE_SESSION_FRAGMENT}
`;

//------------------------------------------------------------------------------
// Chat Messages -------------------------------------------------------------

export const DELETE_CHAT_MESSAGE_MUTATION = gql`
  mutation DeleteChatMessage($timeToken: ID!, $venueId: ID!) {
    chatMessageDelete(timeToken: $timeToken, venueId: $venueId) {
      ok
      errors {
        fieldName
        message
      }
    }
  }
`;
